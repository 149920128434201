<template>
  <div>
    <modal-edit-group-size
      v-if="showModalEditGroupSize"
      :showModal="showModalEditGroupSize"
      :onClickCancel="closeEditModal"
    />

    <div>
      <label class="subtitle">
        <input
          type="checkbox"
          class="checkbox"
          @change="saveYieldSettings"
          v-model="mYieldSettings.ApplyGroupSize"
          :checked="mYieldSettings.ApplyGroupSize"
          :disabled="mYieldSettings.MeetingtypeId !== 1"
        />
        Use group sizes
      </label>
    </div>

    <article
      v-if="mYieldSettings.MeetingtypeId !== 1"
      class="message is-warning has-margin-top"
    >
      <div class="message-body">
        This option can only be activated on meeting rooms
      </div>
    </article>

    <article
      v-if="
        mYieldSettings.MeetingtypeId === 1 && !mYieldSettings.ApplyGroupSize
      "
      class="message is-info has-margin-top"
    >
      <div class="message-body">
        Activate if you want to apply a discount or surcharge on certain group
        sizes.
      </div>
    </article>

    <div class="has-text-right">
      <a
        v-if="mYieldSettings.ApplyGroupSize"
        @click="setShowEditGroupSize"
        class="is-size-7"
      >
        <span class="icon">
          <font-awesome-icon :icon="['fas', 'edit']" />
        </span>
        <span>Edit</span>
      </a>
    </div>

    <table
      v-if="mYieldSettings.ApplyGroupSize"
      class="table is-fullwidth is-narrow is-striped"
    >
      <thead>
        <tr>
          <th>Group size</th>
          <th>Discount / surcharge</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(groupSize, index) in sortedGroupSizes" :key="index">
          <td width="100">
            <span
              >{{ getPreviousGroupSize(index) }} - {{ groupSize.MaxSize }}</span
            >
          </td>
          <td width="75">
            <span>{{ groupSize.Percentage }}%</span>
            <span v-if="groupSize.Percentage === 0">
              No discount or surcharge</span
            >
            <span v-if="groupSize.Percentage < 0"> discount</span>
            <span v-if="groupSize.Percentage > 0"> surcharge</span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import yieldProvider from '@/providers/yield'

const ModalEditYieldGroupSize = () =>
  import('@/components/Yield/ModalEditYieldGroupSize')

export default {
  components: {
    'modal-edit-group-size': ModalEditYieldGroupSize,
  },

  data() {
    return {
      mYieldSettings: null,
      showModalEditGroupSize: false,
    }
  },

  computed: {
    ...mapState('yieldStore', ['yieldSettingsState']),

    sortedGroupSizes() {
      let self = this
      let groupsizes = []
      if (
        self.yieldSettingsState.yieldSettings &&
        self.yieldSettingsState.yieldSettings.GroupSizes.length > 0
      ) {
        groupsizes = self.yieldSettingsState.yieldSettings.GroupSizes.sort(
          function(a, b) {
            return a.MaxSize - b.MaxSize
          }
        )
      }
      return groupsizes
    },
  },

  created() {
    this.mYieldSettings = JSON.parse(
      JSON.stringify(this.yieldSettingsState.yieldSettings)
    )
  },

  methods: {
    ...mapMutations('yieldStore', ['setYieldSettingsState']),

    getPreviousGroupSize(index) {
      let self = this
      let previousGroupSize = null
      let size = 1

      if (index > 0) {
        previousGroupSize =
          self.yieldSettingsState.yieldSettings.GroupSizes[index - 1]
        if (previousGroupSize) {
          size = Number(previousGroupSize.MaxSize) + 1
        }
      }
      return size
    },

    saveYieldSettings() {
      let self = this

      self.yieldSettingsState.yieldSettings = self.mYieldSettings

      yieldProvider.methods
        .updateLocationYieldSettings(self.mYieldSettings)
        .then((response) => {
          if (response.status === 204) {
            self.setYieldSettingsState(self.yieldSettingsState)
          }
        })
    },

    setShowEditGroupSize() {
      this.showModalEditGroupSize = true
    },

    closeEditModal() {
      this.showModalEditGroupSize = false
    },
  },
}
</script>

<style></style>
